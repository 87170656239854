.payment-item{
    width: 100%;

}


.default-container{
    width: 100%;
    max-width: 600px;
}

.divisor{
    min-width: 80%;
    width: 80%;
    ::after{
        content: "";
        width: 100%;
        border-bottom: 1px solid #949494;
    }
}


*{
    font-family: 'Montserrat', sans-serif;
}

.arrow{
    transition: 0.5s;
}

.arrow-rotate {
    transition: 0.5s;
    transform: rotate(-90deg);
}

  
