@import './common.scss';
body{
    //background-color: white;
}


html{ // TEMPORAL
    background-image: url('../img/dot-grid.png');
}

.top-menu-bg{
    background-color: #71c9ce;
}

.top-menu-color{
    color: rgb(26, 46, 47)
}

.title-primary-color{
    color: white
}


.primary-color{
    color: #2B4D4F
}

.title-primary-bg{
    background-color: #71c9ce;
}


.primary-bg{
    background-color: #e3fdfd;
}
.secondary-bg{
    background-color: #a6e3e9;
}


.messages-bg{
    background-color: #3f3f44;
    color: white;
}

.bad-messages{
    background-color: #ee8572;
    color: white
}


.default-input{
    width: 100%;
    /* background-color: #edf2f7; */
    background-color: white;
    color: #4a5568;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    line-height: 1.25;
    
    border-width: 2px;
    
    border-radius: 0.25rem;
    &:focus{
        border-color: #a0aec0;
    }

}

.input-error{
    border-color: #f15e5e;
}